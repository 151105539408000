import routes from './routes'
import {createRouter, createWebHistory} from 'vue-router'
import store from '@store'

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
    // Check if auth is required on this route
    // (including nested routes).
    const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

    // If auth isn't required for the route, just continue.
    if (!authRequired) return next()
    if (authRequired) {
        let userInformation = JSON.parse(localStorage.getItem('userInformation'));
        if (!userInformation?.userGroupNames || !userInformation?.userGroupTechNames) {
            localStorage.clear();
            store.dispatch("logOut").then(r => {
                return redirectToLogin();
            });
        }
    }

    // If auth is required and the user is logged in...
    if (store.getters['loggedIn']) {
        // Validate the local user token...
        store.dispatch('validate').then((validUser) => {
            // Then continue if the token still represents a valid user,
            // otherwise redirect to login.
            if (!validUser) return redirectToLogin()
        })
    } else {
        return redirectToLogin();
    }
    const accessTo = routeTo.meta?.accessTo;
    const restrictedTo = routeTo.meta?.restrictedTo;
    if (!accessTo && !restrictedTo) {
        return next()
    }

    function redirectToLogin() {
        // Pass the original route to the login component
        next({name: 'login', query: {redirectFrom: routeTo.fullPath}})
    }

    function redirectToUnauthorized() {
        // Pass the original route to the login component
        next({name: 'unauthorized'})
    }
    return next()

})

router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // Complete the redirect.
                            next(...args)
                            reject(new Error('Redirected'))
                        } else {
                            resolve()
                        }
                    })
                } else {
                    // Otherwise, continue resolving the route.
                    resolve()
                }
            })
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return
    }
    // If we reach this point, continue resolving the route.
    next()
})
export default router