<script>
export default {
  components: {}

}
</script>

<template>
  <metainfo>
    <template v-slot:title="{ content }">
      {{ content ? this.$i18n.t(content) + ` | ` + this.$i18n.t('app_name') : this.$i18n.t('app_name') }}
    </template>
  </metainfo>
  <RouterView/>
</template>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';
@import '~primevue/resources/themes/saga-blue/theme.css';
@import '~primevue/resources/primevue.min.css';
@import '~primeicons/primeicons.css';
// Design variables and utilities from src/design.
@import '@design';
</style>
