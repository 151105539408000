<script>

export default {
  page: {
    title: 'Unauthorized',
    meta: [{name: 'description', content: 'Unauthorized'}],
  },
  components: {},
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <h1 :class="$style.title">
    {{ $t('unauthorized') }}
    <template v-if="resource">
      {{ resource }}
    </template>
  </h1>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
