import {createApp} from 'vue'
import {createMetaManager, plugin as vueMetaPlugin} from 'vue-meta'

import App from './App.vue'
import router from './router'
import store from './store'
import 'jquery'
import 'popper.js'
import 'bootstrap'
import 'metismenu'
import {createI18n} from 'vue-i18n'
import {fr} from '@lang/fr.js'
import {en} from '@lang/en.js'
import moment from "moment/moment";
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

/**PrimeVue**/
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import InputNumber from 'primevue/inputnumber';
import Checkbox from 'primevue/checkbox';
import Password from 'primevue/password'
import Message from 'primevue/message'
import Dialog from 'primevue/dialog'
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown'
import InputMask from 'primevue/inputmask';
import Avatar from 'primevue/avatar';
import FileUpload from 'primevue/fileupload';
import Tooltip from 'primevue/tooltip';
import ScrollPanel from 'primevue/scrollpanel';
import ProgressBar from 'primevue/progressbar';
import Timeline from 'primevue/timeline';
import Card from 'primevue/card';
import MultiSelect from 'primevue/multiselect';
import SelectButton from 'primevue/selectbutton';
import ToastService from 'primevue/toastservice';
//import ColorPicker from 'primevue/colorPicker';

/**LIB**/
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VuelidatePlugin from '@vuelidate/core'

const i18n = createI18n({
    locale: navigator.language,
    fallbackLocale: 'fr',
    fallbackWarn: false,
    missingWarn: false,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    messages: {
        'fr': fr,
        'en': en
    },
})

moment.locale('fr');


const appVue = createApp(App)
    .use(store)
    .use(i18n)
    .use(createMetaManager())
    .use(vueMetaPlugin)
    .use(router)
    .use(FloatingVue)
    .use(VueLoading)
    .use(VuelidatePlugin)
    .use(ToastService)
    .use(PrimeVue,{
        locale: {
            dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
            dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
            dayNamesMin: ["Di","Lu","Ma","Me","Je","Ve","Sa"],
            monthNames: ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Aout","Septembre","Octobre","Novembre","Décembre"],
            monthNamesShort: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jui","Jul", "Aou", "Sep", "Oct", "Nov", "Dec"],
            today: "Aujourd'hui",
            firstDayOfWeek: 1,
            weekHeader: 'Wk',
        }
    });

appVue.component("DataTable", DataTable);
appVue.component("Column", Column);
appVue.component("InputText", InputText);
appVue.component("Textarea", Textarea);
appVue.component("InputNumber", InputNumber);
appVue.component("Checkbox", Checkbox);
appVue.component("Password", Password);
appVue.component("Message", Message);
appVue.component("Dialog", Dialog);
appVue.component("Calendar", Calendar);
appVue.component("Dropdown", Dropdown);
appVue.component("InputMask", InputMask);
appVue.component("FileUpload", FileUpload);
appVue.component("Tooltip", Tooltip);
appVue.component("Avatar", Avatar);
appVue.component("ScrollPanel", ScrollPanel);
appVue.component("ProgressBar", ProgressBar);
appVue.component("Timeline", Timeline);
appVue.component("Card", Card);
appVue.component("MultiSelect", MultiSelect);
appVue.component("SelectButton", SelectButton);
//appVue.component("ColorPicker", ColorPicker);


appVue.mount('#app');
appVue.config.unwrapInjectedRef = true;